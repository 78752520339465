import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    
    console.log('Stripe Debug:', {
      key: key ? `${key.substring(0, 10)}...` : 'missing',
      isLiveKey: key?.startsWith('pk_live_'),
      environment: process.env.NODE_ENV,
      buildTime: process.env.NEXT_PUBLIC_BUILD_TIME || 'unknown'
    });

    if (!key?.startsWith('pk_live_')) {
      console.error('Warning: Stripe key is not a live key!');
    }

    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export default getStripe;