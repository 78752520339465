import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import styles from '../styles/ResultsPage.module.css';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';

const capitalizeLocation = (location) => {
  return location
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const ResultsPage = () => {
  const { isAdmin } = useOutletContext();
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    course: '',
    horseName: '',
  });
  const [openDropdown, setOpenDropdown] = useState(null); // 'course' or 'horse' or null

  const API_URL = process.env.REACT_APP_API_URL;

  const getAuthHeaders = () => {
    try {
        // Try both possible token storage methods
        const accessToken = localStorage.getItem('access_token');
        const userStr = localStorage.getItem('user');
        
        console.log('Debug - localStorage:', {
            accessToken: !!accessToken,
            userStr: !!userStr
        });

        if (accessToken) {
            return {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
        }

        if (userStr) {
            const user = JSON.parse(userStr);
            if (user?.access) {
                return {
                    'Authorization': `Bearer ${user.access}`,
                    'Content-Type': 'application/json',
                };
            }
        }

        console.error('No valid token found in either storage location');
        return null;
    } catch (error) {
        console.error('Error getting auth headers:', error);
        return null;
    }
};

const fetchResults = async () => {
    try {
        const headers = getAuthHeaders();
        if (!headers) {
            throw new Error('No valid authentication token found');
        }

        console.log('Fetching results from:', `${API_URL}/api/tips/`);
        const response = await axios.get(`${API_URL}/api/tips/`, { headers });
        
        const completedTips = response.data.filter(tip => tip.result && tip.result !== 'PENDING');
        const filteredTips = completedTips.filter(tip => {
            const tipDate = new Date(tip.date);
            const startDate = new Date(filters.startDate);
            const endDate = new Date(filters.endDate);
            return (!filters.startDate || tipDate >= startDate) && (!filters.endDate || tipDate <= endDate);
        });

        setTips(filteredTips);
    } catch (err) {
        console.error('Error details:', {
            status: err.response?.status,
            statusText: err.response?.statusText,
            data: err.response?.data,
            message: err.message
        });

        if (err.response?.status === 401) {
            setError('Your session has expired. Please log in again.');
            localStorage.removeItem('user');
            window.location.href = '/login';
        } else {
            setError(err.response?.data?.message || err.message || 'Failed to fetch results');
        }
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    fetchResults();
  }, [filters]);

  // Get unique courses and horse names from non-pending tips
  const { uniqueCourses, uniqueHorses } = useMemo(() => {
    const completedTips = tips.filter(tip => tip.result && tip.result !== 'PENDING');
    return {
      uniqueCourses: [...new Set(completedTips.map(tip => tip.location))].sort(),
      uniqueHorses: [...new Set(completedTips.map(tip => tip.horse))].sort()
    };
  }, [tips]);

  // Get suggestions based on input value
  const getSuggestions = (input, items) => {
    if (!input) return items.slice(0, 5); // Show first 5 items when no input
    
    const inputValue = input.toLowerCase();
    return items
      .filter(item => item.toLowerCase().includes(inputValue))
      .slice(0, 5);
  };

  // Memoized suggestions
  const courseSuggestions = useMemo(() => 
    getSuggestions(filters.course, uniqueCourses),
    [filters.course, uniqueCourses]
  );

  const horseSuggestions = useMemo(() => 
    getSuggestions(filters.horseName, uniqueHorses),
    [filters.horseName, uniqueHorses]
  );

  // Filtered tips with memoization
  const filteredTips = useMemo(() => {
    return tips
      .filter(tip => tip.result && tip.result !== 'PENDING')
      .filter(tip => {
        const matchesDate = (!filters.startDate || new Date(tip.date) >= new Date(filters.startDate)) &&
                           (!filters.endDate || new Date(tip.date) <= new Date(filters.endDate));
        const matchesCourse = !filters.course || 
                             tip.location.toLowerCase().includes(filters.course.toLowerCase());
        const matchesHorse = !filters.horseName || 
                            tip.horse.toLowerCase().includes(filters.horseName.toLowerCase());
        
        return matchesDate && matchesCourse && matchesHorse;
      });
  }, [tips, filters]);

  // Function to get the row class based on the result
  const getRowClass = (result) => {
    switch (result) {
      case 'WON':
        return styles.winner;
      case 'LOST':
        return styles.loser;
      case 'NR':
        return styles.nr;
      case 'PLACED':
        return styles.placed;
      default:
        return '';
    }
  };

  // Calculate total profit/loss considering tip types
  const profitLoss = calculateProfitLoss(tips);
  const totalProfitLoss = tips.reduce((total, tip) => {
    // Use win only profit for "Solid Pick" tips
    if (tip.type === 'Solid Pick') {
      return total + (profitLoss.winOnlyProfit / tips.length);
    }
    // Use each way profit for "Each Way" tips
    return total + (profitLoss.eachWayProfit / tips.length);
  }, 0);

  const clearFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      course: '',
      horseName: '',
    });
  };

  const handleSuggestionClick = (value, type) => {
    setFilters(prev => ({ ...prev, [type]: value }));
    setOpenDropdown(null); // Close dropdown after selection
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.filterWrapper}`)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const clearInput = (fieldName) => {
    setFilters(prev => ({ ...prev, [fieldName]: '' }));
    setOpenDropdown(null);
  };

  if (loading) return <LoadingSpinner text="Loading results..." />;
  if (error) return <div className={styles.error}>Error: {error}</div>;

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.header}>
        <h2>Previous Results</h2>
      </div>

      <div className={styles.filtersSection}>
        <div className={styles.filterGroup}>
          <div className={styles.filterWrapper}>
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={e => setFilters(prev => ({ ...prev, startDate: e.target.value }))}
              className={styles.filterInput}
            />
          </div>
          <div className={styles.filterWrapper}>
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={e => setFilters(prev => ({ ...prev, endDate: e.target.value }))}
              className={styles.filterInput}
            />
          </div>
          <div className={styles.filterWrapper}>
            <input
              type="text"
              name="course"
              placeholder="Filter by course..."
              value={filters.course}
              onChange={e => setFilters(prev => ({ ...prev, course: e.target.value }))}
              onFocus={() => setOpenDropdown('course')}
              className={styles.filterInput}
            />
            {filters.course && (
              <button
                className={styles.clearInputBtn}
                onClick={() => clearInput('course')}
                type="button"
                aria-label="Clear course filter"
              >
                ✕
              </button>
            )}
            {openDropdown === 'course' && courseSuggestions.length > 0 && (
              <div className={styles.suggestions}>
                {courseSuggestions.map(course => (
                  <div
                    key={course}
                    className={styles.suggestionItem}
                    onClick={() => handleSuggestionClick(course, 'course')}
                  >
                    {course}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.filterWrapper}>
            <input
              type="text"
              name="horseName"
              placeholder="Filter by horse name..."
              value={filters.horseName}
              onChange={e => setFilters(prev => ({ ...prev, horseName: e.target.value }))}
              onFocus={() => setOpenDropdown('horse')}
              className={styles.filterInput}
            />
            {filters.horseName && (
              <button
                className={styles.clearInputBtn}
                onClick={() => clearInput('horseName')}
                type="button"
                aria-label="Clear horse name filter"
              >
                ✕
              </button>
            )}
            {openDropdown === 'horse' && horseSuggestions.length > 0 && (
              <div className={styles.suggestions}>
                {horseSuggestions.map(horse => (
                  <div
                    key={horse}
                    className={styles.suggestionItem}
                    onClick={() => handleSuggestionClick(horse, 'horseName')}
                  >
                    {horse}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        <div className={styles.filterActions}>
          <button 
            className={styles.clearFiltersBtn}
            onClick={clearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>

      <table className={styles.resultsTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Horse</th>
            <th>Location</th>
            <th>Odds</th>
            <th>Type</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {filteredTips.map((tip) => (
            <tr key={tip.id} className={getRowClass(tip.result)}>
              <td>{new Date(tip.date).toLocaleDateString()}</td>
              <td>{tip.horse}</td>
              <td>{capitalizeLocation(tip.location)}</td>
              <td>{Number(tip.odds).toFixed(2)}</td>
              <td>{tip.type}</td>
              <td>{tip.result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsPage;