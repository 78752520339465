import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { login, getCurrentUser } from '../services/authService';
import styles from '../styles/LoginPage.module.css';

const LoginPage = () => {
  console.log('API URL:', process.env.REACT_APP_API_URL);
  
  const [formData, setFormData] = useState({
    username_or_email: '',  // Changed from username
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const justRegistered = new URLSearchParams(location.search).get('registered');

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.access) {
      navigate('/vip/tips');
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Clear error when user starts typing
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
  
    try {
      const response = await login(formData.username_or_email, formData.password);
      if (response.access) {
        setFormData({ username: '', password: '' });
        navigate('/vip/tips');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err.response?.data || err.message);
      setError(
        err.response?.data?.detail || 
        err.response?.data?.message || 
        'Invalid credentials. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>Login to Your Account</h2>
        {error && <p className={styles.error}>{error}</p>}
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email or Username</label>
            <input
              className={styles.input}
              type="text"
              name="username_or_email"  // Changed from username
              value={formData.username_or_email}  // Changed from username
              onChange={handleChange}
              placeholder="Enter your email or username"
              disabled={isLoading}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Password</label>
            <input
              className={styles.input}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              disabled={isLoading}
              required
            />
          </div>
          <button 
            type="submit" 
            className={styles.button}
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <p className={styles.forgotPassword}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
        {justRegistered && (
          <div className={styles.success}>
            Registration successful! Please check your email to verify your account.
          </div>
        )}
      </div>
    </>
  );
};

export default LoginPage;