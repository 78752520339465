import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../styles/RegisterPage.module.css';
import axios from 'axios';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: ''
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!termsAccepted) {
      setError('You must accept the Terms of Service and Privacy Policy to register');
      return;
    }
    
    try {
      const response = await axios.post(`${API_URL}/api/accounts/register/`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.status === 201) {
        console.log('Registration successful:', response.data);
        navigate('/check-email', { state: { email: formData.email } });
      }
    } catch (err) {
      console.error('Registration error:', err);
      if (err.response?.data) {
        // Handle specific error messages
        const errorData = err.response.data;
        if (errorData.password) {
          setError(`Password error: ${errorData.password.join(' ')}`);
        } else if (errorData.username) {
          setError(`Username error: ${errorData.username.join(' ')}`);
        } else if (errorData.email) {
          setError(`Email error: ${errorData.email.join(' ')}`);
        } else {
          setError('Registration failed. Please try again.');
        }
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
};

return (
  <>
    <div className={styles.container}>
      <h2 className={styles.title}>Create Your Account</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>Username</label>
          <input
            className={styles.input}
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your username"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Email</label>
          <input
            className={styles.input}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Password</label>
          <input
            className={styles.input}
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
          />
        </div>

        <div className={styles.termsContainer}>
          <label className={styles.termsLabel}>
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className={styles.checkbox}
            />
            <span className={styles.termsText}>
              I agree to the{' '}
              <Link to="/terms" target="_blank" className={styles.termsLink}>Terms of Service</Link>,{' '}
              <Link to="/privacy" target="_blank" className={styles.termsLink}>Privacy Policy</Link>, and{' '}
              <Link to="/disclaimer" target="_blank" className={styles.termsLink}>Disclaimer</Link>
            </span>
          </label>
        </div>

        <button 
          type="submit" 
          className={styles.button}
          disabled={isLoading || !termsAccepted}
        >
          {isLoading ? 'Creating Account...' : 'Register'}
        </button>
      </form>
      <p className={styles.loginLink}>
        Already have an account? <Link to="/login">Log in here</Link>
      </p>
    </div>
  </>
);
};

export default RegisterPage;