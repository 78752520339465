import styles from '../styles/LegalPages.module.css';

const PrivacyPage = () => {
  return (
    <div className={styles.legalContainer}>
      <h1 className={styles.title}>Privacy Policy</h1>

      <section className={styles.section}>
        <h2>1. Information We Collect</h2>
        <p>We collect information you provide directly to us, including:</p>
        <ul>
          <li>Name and email address</li>
          <li>Payment information</li>
          <li>Account preferences</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and maintain our services</li>
          <li>Process your payments</li>
          <li>Send you updates and marketing communications</li>
          <li>Improve our services</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>3. Information Sharing</h2>
        <p>We do not sell your personal information. We may share your information with:</p>
        <ul>
          <li>Payment processors</li>
          <li>Service providers</li>
          <li>When required by law</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>4. Data Security</h2>
        <p>We implement appropriate security measures to protect your personal information.</p>
      </section>

      <section className={styles.section}>
        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal information</li>
          <li>Correct inaccurate information</li>
          <li>Request deletion of your information</li>
          <li>Opt-out of marketing communications</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPage;