import styles from '../styles/LegalPages.module.css';

const TermsPage = () => {
  return (
    <div className={styles.legalContainer}>
      <h1 className={styles.title}>Terms of Service</h1>
      
      <section className={styles.section}>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using MisterTipster's services, you accept and agree to be bound by these Terms of Service.</p>
      </section>

      <section className={styles.section}>
        <h2>2. Service Description</h2>
        <p>MisterTipster provides horse racing tips and analysis as a subscription-based service. Our content is for informational purposes only.</p>
      </section>

      <section className={styles.section}>
        <h2>3. Subscription and Payments</h2>
        <p>Subscription fees are charged monthly. You may cancel your subscription at any time. Refunds are provided at our discretion.</p>
      </section>

      <section className={styles.section}>
        <h2>4. User Obligations</h2>
        <ul>
          <li>You must be at least 18 years old to use our services</li>
          <li>You must provide accurate account information</li>
          <li>You are responsible for maintaining account security</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>5. Intellectual Property</h2>
        <p>All content provided by MisterTipster is protected by copyright and other intellectual property laws.</p>
      </section>

      <section className={styles.section}>
        <h2>6. Termination</h2>
        <p>We reserve the right to terminate or suspend accounts that violate these terms or for any other reason at our discretion.</p>
      </section>

      <section className={styles.section}>
        <h2>7. Changes to Terms</h2>
        <p>We may modify these terms at any time. Continued use of our services constitutes acceptance of modified terms.</p>
      </section>
    </div>
  );
};

export default TermsPage;