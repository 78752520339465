import React from 'react';
import styles from '../styles/LoadingSpinner.module.css';

const LoadingSpinner = ({ text = 'Loading...' }) => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p>{text}</p>
  </div>
);

export default LoadingSpinner;