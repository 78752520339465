import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/AccountSettings.module.css';
import { 
    getCurrentUser, 
    getAuthHeader, 
    hasValidToken, 
    logout 
} from '../services/authService';
import axios from 'axios';
import getStripe from '../utils/stripe';
import { Link } from 'react-router-dom';

const AccountSettings = () => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
  
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const checkAuth = async () => {
            if (!hasValidToken()) {
                navigate('/login');
                return;
            }
            
            try {
                await checkSubscription();
            } catch (error) {
                if (error.response?.status === 401) {
                    logout();
                    navigate('/login');
                }
            }
        };
        
        checkAuth();
    }, [navigate]);

    const checkSubscription = async () => {
        try {
            setError(null);
            const response = await axios.get('/api/subscription/check/', {
                headers: getAuthHeader()
            });
            setSubscription(response.data);
        } catch (error) {
            console.error('Subscription check error:', error.response?.data || error);
            if (error.response?.status !== 401) {
                setError('Failed to load subscription status: ' + 
                    (error.response?.data?.detail || error.message));
            }
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async () => {
        try {
            setError(null);
            setIsProcessing(true);

            const response = await axios.post(
                '/api/subscription/create-checkout-session/',
                {},
                { headers: getAuthHeader() }
            );

            const stripe = await getStripe();
            const { error } = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId
            });

            if (error) {
                setError(error.message);
            }
        } catch (err) {
            setError('Failed to initiate subscription: ' + err.message);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleUnsubscribe = async () => {
        try {
            setError(null);
            setIsProcessing(true);

            await axios.post(
                '/api/subscription/cancel/',
                {},
                { headers: getAuthHeader() }
            );

            // Refresh subscription status
            await checkSubscription();
        } catch (err) {
            setError('Failed to cancel subscription: ' + err.message);
        } finally {
            setIsProcessing(false);
        }
    };

    if (loading) {
        return <div className={styles.loading}>Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <h1>Account Settings</h1>
            
            {error && (
                <div className={styles.error}>
                    {error}
                </div>
            )}
            
            <div className={styles.subscriptionSection}>
                <h2>Subscription Status</h2>
                {subscription?.is_active ? (
                    <div>
                        <p className={styles.status}>
                            Status: {subscription.is_canceling ? 'Active (Cancelling)' : 'Active'}
                        </p>
                        {subscription.is_canceling ? (
                            <>
                                <p className={styles.cancelMessage}>
                                    Your subscription will end on:
                                </p>
                                <p className={styles.renewalDate}>
                                    {new Date(subscription.renewal_date).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                    })}
                                </p>
                                <div className={styles.buttonContainer}>
                                    <Link to="/VIP" className={styles.vipButton}>
                                        Access VIP Area
                                    </Link>
                                    <div className={styles.resubscribeInfo}>
                                        <p>You can resubscribe after your current subscription ends.</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className={styles.activeMessage}>
                                    Your subscription is active and will automatically renew on:
                                </p>
                                <p className={styles.renewalDate}>
                                    {new Date(subscription.renewal_date).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                    })}
                                </p>
                                <div className={styles.buttonContainer}>
                                    <Link to="/VIP" className={styles.vipButton}>
                                        Access VIP Area
                                    </Link>
                                    <button 
                                        onClick={handleUnsubscribe}
                                        className={styles.cancelButton}
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? 'Processing...' : 'Cancel Auto-Renewal'}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        <p className={styles.status}>Status: Inactive</p>
                        <p className={styles.subscribeMessage}>
                            Subscribe now to unlock exclusive VIP tips and premium features!
                        </p>
                        <div className={styles.buttonContainer}>
                            <button 
                                onClick={handleSubscribe}
                                className={styles.subscribeButton}
                                disabled={isProcessing}
                            >
                                {isProcessing ? 'Processing...' : 'Subscribe - £5/month'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountSettings;