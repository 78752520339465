import { Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { getCurrentUser } from '../services/authService';
import { checkSubscription } from '../services/subscriptionService';

const ProtectedRoute = ({ children, requiresSubscription = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const location = useLocation();
  const user = getCurrentUser();

  useEffect(() => {
    const verifyAccess = async () => {
      console.log('Starting verifyAccess');
      console.log('User:', user);
      console.log('Is staff member:', user?.is_staff);
      console.log('Requires subscription:', requiresSubscription);
      
      if (!user) {
        console.log('No user found, redirecting to login');
        setIsLoading(false);
        return;
      }

      // Check if user is admin/staff
      const isStaffMember = user?.is_staff === true;
      console.log('Is staff check result:', isStaffMember);
      
      // If user is staff, grant access immediately
      if (isStaffMember) {
        console.log('User is staff, granting access');
        setIsSubscribed(true);
        setIsLoading(false);
        return;
      }

      if (requiresSubscription) {
        console.log('User is not staff, checking subscription status...');
        const hasActiveSubscription = await checkSubscription();
        console.log('Subscription check result:', hasActiveSubscription);
        setIsSubscribed(hasActiveSubscription);
      } else {
        setIsSubscribed(true); // No subscription required
      }

      setIsLoading(false);
    };

    verifyAccess();
  }, [requiresSubscription, user]);

  if (isLoading) {
    return <LoadingSpinner text="Verifying access..." />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiresSubscription && !isSubscribed) {
    return <Navigate to="/account" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;