import React, { useState, useEffect } from 'react';
import styles from '../styles/ResultsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';

const capitalizeLocation = (location) => {
  return location
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const PreviousResultsPage = () => {
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tipsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/tips/public-results/`);
        const completedTips = response.data.filter(tip => 
          tip.result && tip.result !== 'PENDING'
        );
        setTips(completedTips);
      } catch (err) {
        console.error('Error fetching results:', err);
        setError(err.message || 'Failed to fetch results');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const getRowClass = (result) => {
    switch (result) {
      case 'WON':
        return styles.winner;
      case 'LOST':
        return styles.loser;
      case 'NR':
        return styles.nr;
      case 'PLACED':
        return styles.placed;
      default:
        return '';
    }
  };

  // Calculate pagination
  const indexOfLastTip = currentPage * tipsPerPage;
  const indexOfFirstTip = indexOfLastTip - tipsPerPage;
  const currentTips = tips.slice(indexOfFirstTip, indexOfLastTip);
  const totalPages = Math.ceil(tips.length / tipsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <LoadingSpinner text="Loading results..." />;
  if (error) return <div className={styles.error}>Error: {error}</div>;

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.header}>
        <h2>Previous Results</h2>
      </div>

      <table className={styles.resultsTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Horse</th>
            <th>Location</th>
            <th>Odds</th>
            <th>Type</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {currentTips.map((tip) => (
            <tr key={tip.id} className={getRowClass(tip.result)}>
              <td>{new Date(tip.date).toLocaleDateString()}</td>
              <td>{tip.horse}</td>
              <td>{capitalizeLocation(tip.location)}</td>
              <td>{Number(tip.odds).toFixed(2)}</td>
              <td>{tip.type}</td>
              <td>{tip.result}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        <button 
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={styles.paginationButton}
        >
          Previous
        </button>
        
        <span className={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </span>
        
        <button 
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={styles.paginationButton}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PreviousResultsPage;