import styles from '../styles/LegalPages.module.css';

const DisclaimerPage = () => {
  return (
    <div className={styles.legalContainer}>
      <h1 className={styles.title}>Disclaimer</h1>

      <section className={styles.section}>
        <h2>Betting Risks</h2>
        <p>Horse racing betting involves substantial risk and may not be suitable for everyone. You must be aware that you could lose some or all of your invested money.</p>
      </section>

      <section className={styles.section}>
        <h2>No Guaranteed Returns</h2>
        <p>While we strive to provide accurate and well-researched tips, we cannot and do not guarantee any profits or returns. Past performance is not indicative of future results.</p>
      </section>

      <section className={styles.section}>
        <h2>Personal Responsibility</h2>
        <p>You are solely responsible for your betting decisions. Our tips and analysis should be considered as opinions only and not as financial advice.</p>
      </section>

      <section className={styles.section}>
        <h2>Limitation of Liability</h2>
        <p>MisterTipster and its team members shall not be held liable for any losses incurred from following our tips or using our services. You agree to use our service at your own risk.</p>
      </section>

      <section className={styles.section}>
        <h2>Responsible Gambling</h2>
        <p>We strongly encourage responsible gambling. Never bet more than you can afford to lose. If you feel you may have a gambling problem, please seek professional help.</p>
      </section>
    </div>
  );
};

export default DisclaimerPage;