import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
  const { token } = useParams();
  const [status, setStatus] = useState('Verifying...');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (token) {
      axios.post(`${API_URL}/api/accounts/verify-email/`, { token })
        .then(() => {
          setStatus('Email verified successfully! You can now log in.');
          setTimeout(() => {
            window.location.href = '/login';
          }, 3000);
        })
        .catch((error) => {
          console.error('Verification error:', error);
          setStatus('Verification failed. Please try again or contact support.');
        });
    }
  }, [token]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-dark-green">
      <div className="p-8 bg-darker-green rounded-lg shadow-lg">
        <h1 className="text-2xl text-yellow mb-4">Email Verification</h1>
        <p className="text-white">{status}</p>
      </div>
    </div>
  );
};

export default EmailVerification;