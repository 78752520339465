import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { hasValidToken } from '../services/authService';
import styles from '../styles/FreeTipsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';
import getStripe from '../utils/stripe';
import { getAuthHeader } from '../services/authService';

const FreeTipsPage = () => {
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTips, setExpandedTips] = useState(new Set());
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [napPerformance, setNapPerformance] = useState({
    currentStreak: 0,
    bestStreak: 0,
    totalProfit: 0
  });
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [napStats, setNapStats] = useState(null);
  const [authStatus, setAuthStatus] = useState({
    isLoggedIn: false,
    isSubscribed: false,
    hadTrial: false,
    isStaff: false
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [napHistory, setNapHistory] = useState([]);

  useEffect(() => {
    checkAuthStatus();
    fetchNapStats();
    fetchNapHistory();
  }, []);

  const checkAuthStatus = async () => {
    const token = localStorage.getItem('access_token');
    const user = JSON.parse(localStorage.getItem('user'));
    const isStaff = user?.is_staff || false;

    if (!token) {
      setAuthStatus({ isLoggedIn: false, isSubscribed: false, hadTrial: false, isStaff: false });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/subscription/check/`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setAuthStatus({
        isLoggedIn: true,
        isSubscribed: response.data.is_active,
        hadTrial: response.data.had_trial,
        isStaff: isStaff
      });

      if (response.data.is_active || isStaff) {
        fetchTips();
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem('access_token');
        setAuthStatus({ isLoggedIn: false, isSubscribed: false, hadTrial: false, isStaff: false });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNapStats = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/tips/public-results/`);
      const napTips = response.data.filter(tip => tip.type === 'NAP');
      const completedTips = napTips.filter(tip => tip.result !== 'PENDING' && tip.result !== 'NR');
      
      // Get previous month's tips
      const today = new Date();
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
      
      const lastMonthTips = completedTips.filter(tip => {
        const tipDate = new Date(tip.date);
        return tipDate >= lastMonth && tipDate <= lastMonthEnd;
      });

      // Calculate last month's profits with £10 stakes
      const lastMonthProfits = lastMonthTips.reduce((total, tip) => {
        if (tip.result === 'WON') {
          return total + ((Number(tip.odds) - 1) * 10);
        }
        return total - 10;
      }, 0);

      const wins = completedTips.filter(tip => tip.result === 'WON').length;
      const strikeRate = ((wins / completedTips.length) * 100).toFixed(1);
      
      const allTimeProfits = completedTips.reduce((total, tip) => {
        if (tip.result === 'WON') {
          return total + ((Number(tip.odds) - 1) * 10);
        }
        return total - 10;
      }, 0);

      setNapStats({
        strikeRate,
        profits: allTimeProfits.toFixed(2),
        totalTips: completedTips.length,
        lastMonthProfits: lastMonthProfits.toFixed(2),
        lastMonthName: lastMonth.toLocaleString('default', { month: 'long' })
      });
    } catch (error) {
      console.error('Error fetching NAP stats:', error);
    }
  };

  const fetchTips = async () => {
    try {
      const headers = {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
      };

      const tipsResponse = await axios.get(`${API_URL}/api/tips/`, { headers });
      const today = new Date().toISOString().split('T')[0];
      const todaysTips = tipsResponse.data.filter(tip => tip.date === today);
      setTips(todaysTips);

      const resultsResponse = await axios.get(`${API_URL}/api/tips/public-results/`, { headers });
      const stats = calculateNapStats(resultsResponse.data);
      if (stats) {
        setNapPerformance(stats);
      }
    } catch (err) {
      console.error('Error fetching tips:', err);
      if (err.response?.status === 401) {
        setError('Your session has expired. Please log in again.');
        localStorage.removeItem('access_token');
        navigate('/login');
      } else {
        setError(err.response?.data?.message || err.message || 'Failed to fetch tips');
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateNapStats = (tips) => {
    if (!tips?.length) return null;
    
    const napTips = tips.filter(tip => tip.type?.toLowerCase() === 'nap');
    const completedTips = napTips.filter(tip => tip.result !== 'PENDING' && tip.result !== 'NR');
    
    let currentStreak = 0;
    let bestStreak = 0;
    let totalProfit = 0;
    const betAmount = 10;
    
    const sortedTips = [...completedTips].sort((a, b) => 
        new Date(b.date) - new Date(a.date)
    );

    for (const tip of sortedTips) {
        if (tip.result === 'WON') {
            currentStreak++;
        } else {
            break;
        }
    }

    let tempStreak = 0;
    for (const tip of sortedTips) {
        if (tip.result === 'WON') {
            tempStreak++;
            if (tempStreak > bestStreak) {
                bestStreak = tempStreak;
            }
        } else {
            tempStreak = 0;
        }
    }

    completedTips.forEach(tip => {
        if (tip.result === 'WON') {
            totalProfit += (betAmount * Number(tip.odds)) - betAmount;
        } else if (tip.result === 'LOST' || tip.result === 'PLACED') {
            totalProfit -= betAmount;
        }
    });

    return {
        currentStreak,
        bestStreak,
        totalProfit: Number(totalProfit.toFixed(2))
    };
  };

  const toggleTip = (tipId) => {
    setExpandedTips(prev => {
      const newSet = new Set(prev);
      if (newSet.has(tipId)) {
        newSet.delete(tipId);
      } else {
        newSet.add(tipId);
      }
      return newSet;
    });
  };

  const formatDate = (date) => {
    return new Date().toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const capitalizeWords = (str) => {
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  const formatRaceTime = (dateTimeString) => {
    if (dateTimeString.includes('T')) {
      return dateTimeString.split('T')[1].substring(0, 5); // Get HH:MM from ISO string
    }
    if (dateTimeString.includes(':')) {
      return dateTimeString.substring(0, 5); // Get HH:MM from time string
    }
    const parts = dateTimeString.split(' ');
    return parts.length > 1 ? parts[1].substring(0, 5) : dateTimeString;
  };

  const TipCard = ({ tip }) => {
    const [showAnalysis, setShowAnalysis] = useState(false);
    const time = formatRaceTime(tip.race_time);
    const resultClass = tip.result ? styles[tip.result.toLowerCase()] : styles.pending;
    const resultText = tip.result || 'PENDING';
    
    return (
      <div>
        <div className={`${styles.tipCard} ${resultClass}`}>
          <div className={styles.mainRow}>
            <div className={styles.horseInfo}>
              <img 
                src={tip.image_link} 
                alt="Racing silks" 
                className={styles.silksImage}
              />
              <div className={styles.nameAndTrack}>
                <h3 className={styles.horseName}>{tip.horse}</h3>
                <div className={styles.trackInfo}>
                  <span className={styles.location}>{capitalizeWords(tip.location)}</span>
                  <span className={styles.time}>{time}</span>
                </div>
              </div>
            </div>
            <div className={styles.rightContent}>
              <span className={styles.odds}>{tip.odds}</span>
              <span className={styles.napBadge}>NAP</span>
              <span className={styles.status}>{resultText}</span>
            </div>
          </div>
          
          <div className={styles.detailsRow}>
            <div className={styles.detailItem}>
              <span className={styles.detailLabel}>Jockey</span>
              <span className={styles.detailValue}>{tip.jockey || 'N/A'}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailLabel}>Trainer</span>
              <span className={styles.detailValue}>{tip.trainer || 'N/A'}</span>
            </div>
          </div>
        </div>
        
        <button 
          className={styles.analysisButton}
          onClick={() => setShowAnalysis(!showAnalysis)}
        >
          {showAnalysis ? 'Hide Analysis' : 'View Analysis'}
        </button>
        
        {showAnalysis && (
          <div className={styles.analysisSection}>
            {tip.reasons && (
              <div className={styles.reasonsSection}>
                <h3>Analysis</h3>
                <p className={styles.analysisText}>{tip.reasons}</p>
              </div>
            )}
            {tip.racing_post_comment && (
              <div className={styles.racingPostSection}>
                <h3>Tipster Comment</h3>
                <p className={styles.analysisText}>{tip.racing_post_comment}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Generate fake premium tips based on actual count
  const generatePremiumTeasers = () => {
    const premiumTypes = ['Solid Pick', 'Value Pick', 'Each Way Pick'];
    const tracks = ['Cheltenham', 'Ascot', 'Newmarket', 'Doncaster', 'Aintree'];
    const times = ['14:30', '15:15', '15:45', '16:20', '16:55'];
    
    // Filter out the NAP from total tips count
    const premiumTipsCount = tips.filter(tip => tip.type !== 'NAP').length;
    
    return Array(premiumTipsCount).fill(null).map((_, index) => ({
      id: `fake${index}`,
      horse: 'Premium Selection',
      location: tracks[index % tracks.length],
      race_time: times[index % times.length],
      type: premiumTypes[index % premiumTypes.length],
      odds: (2 + Math.random() * 8).toFixed(2),
      confidence: '8/15',
      reasons: 'Subscribe to view full analysis',
      racing_post_comment: 'Subscribe to view Tipster Comments'
    }));
  };

  const napTip = tips.find(tip => tip.type === 'NAP');
  const premiumTeasers = generatePremiumTeasers();

  const handleSubscribe = async () => {
    // Check both token and user object
    const token = localStorage.getItem('access_token');
    const user = localStorage.getItem('user');
    
    if (!token || !user) {
      navigate('/register');
      return;
    }

    try {
      setError(null);
      setIsProcessing(true);

      const response = await axios.post(
        `${API_URL}/api/subscription/create-checkout-session/`,
        {},
        { headers: getAuthHeader() }
      );

      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError('Failed to initiate subscription: ' + err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchNapHistory = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/tips/public-results/`);
      const napTips = response.data
        .filter(tip => tip.type === 'NAP' && tip.result !== 'PENDING' && tip.result !== 'NR')
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 10);
      setNapHistory(napTips);
    } catch (error) {
      console.error('Error fetching NAP history:', error);
    }
  };

  const UnauthorizedView = () => {
    return (
      <div className={styles.unauthorizedContainer}>
        <div className={styles.topCta}>
          {!authStatus.isLoggedIn ? (
            <Link to="/register" className={styles.ctaButton}>
              Register Now - Start Free Trial
            </Link>
          ) : authStatus.hadTrial ? (
            <button 
              onClick={handleSubscribe}
              className={styles.ctaButton}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Subscribe Now - Only £5/month'}
            </button>
          ) : (
            <button 
              onClick={handleSubscribe}
              className={styles.ctaButton}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Start Your Free Trial'}
            </button>
          )}
        </div>

        <div className={styles.statsSection}>
          <h2>NAP Performance</h2>
          {napStats && (
            <>
              <div className={styles.statsGrid}>
                <div className={styles.statBox}>
                  <div className={styles.statValue}>{napStats.strikeRate}%</div>
                  <div className={styles.statLabel}>Strike Rate</div>
                </div>
                <div className={styles.statBox}>
                  <div className={styles.statValue}>£{napStats.profits}</div>
                  <div className={styles.statLabel}>All-Time Profit (£10 Stakes)</div>
                </div>
                <div className={styles.statBox}>
                  <div className={styles.statValue}>{napStats.totalTips}</div>
                  <div className={styles.statLabel}>Tips Provided</div>
                </div>
              </div>

              <div className={styles.valueProposition}>
                <h3>Last Month's Performance</h3>
                <div className={styles.profitComparison}>
                  <div className={styles.profitBox}>
                    <div className={styles.profitAmount}>£{napStats.lastMonthProfits}</div>
                    <div className={styles.profitLabel}>
                      {napStats.lastMonthName} Profit (£10 Stakes)
                    </div>
                  </div>
                  <div className={styles.subscriptionBox}>
                    <div className={styles.subscriptionAmount}>£5</div>
                    <div className={styles.subscriptionLabel}>Monthly Subscription</div>
                  </div>
                  <div className={styles.profitHighlight}>
                    {Number(napStats.lastMonthProfits) > 5 ? (
                      <span className={styles.profitPositive}>
                        £{(Number(napStats.lastMonthProfits) - 5).toFixed(2)} profit after subscription cost
                      </span>
                    ) : (
                      <span>Results may vary. Past performance doesn't guarantee future results.</span>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles.napHistorySection}>
          <h2>Last 10 NAP Results</h2>
          <div className={styles.tableWrapper}>
            <table className={styles.napTable}>
              <thead>
                <tr>
                  <th>Horse</th>
                  <th>Course</th>
                  <th>Odds</th>
                  <th>Time</th>
                  <th>Date</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {napHistory.map((tip, index) => (
                  <tr key={index}>
                    <td>{tip.horse}</td>
                    <td>{tip.location}</td>
                    <td>{tip.odds}</td>
                    <td>
                      {new Date(tip.race_time).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </td>
                    <td>
                      {new Date(tip.date).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short'
                      })}
                    </td>
                    <td className={styles[tip.result.toLowerCase()]}>
                      {tip.result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return <LoadingSpinner text="Loading tips..." />;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!hasValidToken()) return <UnauthorizedView />;

  if (!authStatus.isSubscribed && !authStatus.isStaff) {
    return <UnauthorizedView />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.dateHeader}>
        <h1>{formatDate(new Date())}</h1>
      </div>

      <div className={styles.napPerformanceContainer}>
        <h2 className={styles.napPerformanceTitle}>NAP Performance</h2>
        <div className={styles.statsGrid}>
          <div className={styles.statBox}>
            <div className={styles.statValue}>{napPerformance.currentStreak}</div>
            <div className={styles.statLabel}>Current Streak</div>
          </div>
          <div className={styles.statBox}>
            <div className={styles.statValue}>{napPerformance.bestStreak}</div>
            <div className={styles.statLabel}>Best Streak</div>
          </div>
          <div className={styles.statBox}>
            <div className={`${styles.statValue} ${napPerformance.totalProfit >= 0 ? styles.profit : styles.loss}`}>
              {napPerformance.totalProfit >= 0 ? '+' : ''}£{napPerformance.totalProfit.toFixed(2)}
            </div>
            <div className={styles.statLabel}>Total Profit/Loss</div>
          </div>
        </div>
      </div>

      <div className={styles.napSection}>
        <h2>NAP of the Day</h2>
        {napTip ? (
          <TipCard tip={napTip} />
        ) : (
          <div className={styles.noTipsMessage}>
            <p>NAP of the Day is usually posted between 9-10am every day</p>
          </div>
        )}
      </div>

      <div className={styles.napHistorySection}>
        <h2>Last 10 NAP Results</h2>
        <div className={styles.tableWrapper}>
          <table className={styles.napTable}>
            <thead>
              <tr>
                <th>Horse</th>
                <th>Course</th>
                <th>Odds</th>
                <th>Time</th>
                <th>Date</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {napHistory.map((tip, index) => (
                <tr key={index}>
                  <td>{tip.horse}</td>
                  <td>{tip.location}</td>
                  <td>{tip.odds}</td>
                  <td>
                    {new Date(tip.race_time).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </td>
                  <td>
                    {new Date(tip.date).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short'
                    })}
                  </td>
                  <td className={styles[tip.result.toLowerCase()]}>
                    {tip.result}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FreeTipsPage;