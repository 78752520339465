import { Analytics } from "@vercel/analytics/react";
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import TipsPage from './pages/TipsPage';
import ResultsPage from './pages/ResultsPage';
import AnalyticsPage from './pages/AnalyticsPage';
import VipPage from './pages/VipPage';
import ProtectedRoute from './components/ProtectedRoute';
import HomeResultsPage from './pages/HomeResultsPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import AccountSettings from './pages/AccountSettings';
import SubscriptionSuccess from './pages/account/success';
import Layout from './components/Layout';
import './styles/globals.css';
import EmailVerification from './components/EmailVerification';
import CheckEmailPage from './pages/CheckEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ContactPage from './pages/ContactPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import DisclaimerPage from './pages/DisclaimerPage';
import ResponsibleGamblingPage from './pages/ResponsibleGamblingPage';
import FreeTipsPage from './pages/FreeTipsPage';
import PreviousResultsPage from './pages/PreviousResultsPage';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setIsAdmin(user?.is_staff || false);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout><LandingPage /></Layout>
    },
    {
      path: "/login",
      element: <Layout><LoginPage /></Layout>
    },
    {
      path: "/register",
      element: <Layout><RegisterPage /></Layout>
    },
    {
      path: "/forgot-password",
      element: <Layout><ForgotPasswordPage /></Layout>
    },
    {
      path: "/results",
      element: <Layout><HomeResultsPage /></Layout>
    },
    {
      path: "/check-email",
      element: <Layout><CheckEmailPage /></Layout>
    },
    {
      path: "/reset-password/:uid/:token",
      element: <Layout><ResetPasswordPage /></Layout>
    },
    {
      path: "/contact",
      element: <Layout><ContactPage /></Layout>
    },
    {
      path: "/terms",
      element: <Layout><TermsPage /></Layout>
    },
    {
      path: "/privacy",
      element: <Layout><PrivacyPage /></Layout>
    },
    {
      path: "/disclaimer",
      element: <Layout><DisclaimerPage /></Layout>
    },
    {
      path: "/responsible-gambling",
      element: <Layout><ResponsibleGamblingPage /></Layout>
    },  
    {
      path: "/free-tips",
      element: <Layout><FreeTipsPage /></Layout>
    },
    {
      path: "/previous-results",
      element: <Layout><PreviousResultsPage /></Layout>
    },
    {
      path: "/vip",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={true}>
            <VipPage />
          </ProtectedRoute>
        </Layout>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/vip/tips" replace />
        },
        {
          path: "tips",
          element: <ProtectedRoute requiresSubscription={true}><TipsPage /></ProtectedRoute>
        },
        {
          path: "results",
          element: <ProtectedRoute requiresSubscription={true}><ResultsPage isAdmin={isAdmin} /></ProtectedRoute>
        },
        {
          path: "analytics",
          element: <ProtectedRoute requiresSubscription={true}><AnalyticsPage /></ProtectedRoute>
        }
      ]
    },
    {
      path: "/account",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={false}>
            <AccountSettings />
          </ProtectedRoute>
        </Layout>
      )
    },
    {
      path: "/account/success",
      element: (
        <Layout>
          <ProtectedRoute requiresSubscription={false}>
            <SubscriptionSuccess />
          </ProtectedRoute>
        </Layout>
      )
    },
    {
      path: "/verify/:token",
      element: <Layout><EmailVerification /></Layout>
    }
  ], {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  });

  return (
    <>
      <RouterProvider router={router} />
      <Analytics />
    </>
  );
}

export default App;