import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import getStripe from '../utils/stripe';
import { getAuthHeader, hasValidToken } from '../services/authService';
import styles from '../styles/LandingPage.module.css';
import { FaChartBar, FaRobot, FaCheckCircle, FaCalculator, FaHistory, FaBell } from 'react-icons/fa'; 

const LandingPage = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchStats();
  }, []);

  const handleSubscribe = async () => {
    const token = localStorage.getItem('access_token');
    const user = localStorage.getItem('user');
    
    if (!token || !user) {
      window.location.href = '/register';
      return;
    }

    try {
      setError(null);
      setIsProcessing(true);

      const response = await axios.post(
        `${API_URL}/api/subscription/create-checkout-session/`,
        {},
        { headers: getAuthHeader() }
      );

      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError('Failed to initiate subscription: ' + err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await fetch(`${API_URL}/api/tips/public-results/`);
      const data = await response.json();
      
      // Get all NAP tips
      const allNapTips = data.filter(tip => 
        tip.type === "NAP" && 
        tip.result !== "NR"
      );

      // Calculate all-time NAP stats
      const napWins = allNapTips.filter(tip => tip.result === "WON").length;
      const napStrikeRate = allNapTips.length > 0 
        ? ((napWins / allNapTips.length) * 100).toFixed(1) 
        : '0';

      const allTimeNapProfits = allNapTips.reduce((total, tip) => {
        if (tip.result === "WON") return total + ((Number(tip.odds) - 1) * 10);
        return total - 10;
      }, 0).toFixed(2);

      // Calculate monthly NAP profits
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      
      const monthlyNapTips = allNapTips.filter(tip => 
        new Date(tip.date) >= firstDayOfMonth
      );

      const monthlyNapProfits = monthlyNapTips.reduce((total, tip) => {
        if (tip.result === "WON") return total + ((Number(tip.odds) - 1) * 10);
        return total - 10;
      }, 0).toFixed(2);

      setStats({ 
        monthlyNapProfits,
        allTimeNapProfits,
        napStrikeRate
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.landingPage}>
      {!loading && stats && (
        <div className={styles.profitsBanner}>
          <div className={styles.profitsContent}>
            <div className={styles.profitsGrid}>
              <div className={styles.profitItem}>
                <h2 className={styles.profitsTitle}>NAP Tips This Month</h2>
                <div className={styles.profitsValue}>
                  £{stats.monthlyNapProfits}
                  <span className={styles.profitsLabel}>Profit to £10 Stakes</span>
                </div>
              </div>
              
              <div className={styles.profitItem}>
                <h2 className={styles.profitsTitle}>All-Time NAP Profits</h2>
                <div className={styles.profitsValue}>
                  £{stats.allTimeNapProfits}
                  <span className={styles.profitsLabel}>Profit to £10 Stakes</span>
                </div>
              </div>

              <div className={styles.profitItem}>
                <h2 className={styles.profitsTitle}>NAP Strike Rate</h2>
                <div className={styles.profitsValue}>
                  {stats.napStrikeRate}%
                  <span className={styles.profitsLabel}>Win Rate</span>
                </div>
              </div>
            </div>
            <Link to="/register" className={styles.bannerCTA}>
              Start Your Free Trial Now
            </Link>
          </div>
        </div>
      )}

      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <h1 className={styles.title}>Data-Driven Horse Racing Tips</h1>
          <p className={styles.subtitle}>
            Advanced analytics and proprietary algorithms combined with expert analysis to identify 
            high-value opportunities in horse racing markets
          </p>
          <div className={styles.heroButtons}>
            <button 
              onClick={handleSubscribe}
              className={styles.ctaButton}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Start Free Trial'}
            </button>
            <p className={styles.trialNote}>
              3-day free trial, then just £5/month
            </p>
          </div>
        </div>
      </div>

      <div className={styles.valueProposition}>
        <h2 className={styles.sectionTitle}>Our Approach</h2>
        <p className={styles.sectionText}>
          We combine sophisticated data analytics with deep horse racing expertise to deliver consistent, 
          profitable selections. Our proprietary scoring system analyzes multiple factors including form, 
          track conditions, and value odds to identify the best betting opportunities each day.
        </p>
      </div>

      <div className={styles.features}>
        <h2 className={styles.featuresTitle}>The MisterTipster Advantage</h2>
        <div className={styles.featuresGrid}>
          <div className={styles.feature}>
            <FaRobot size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Data Analytics</h3>
            <p>Advanced algorithms process vast amounts of racing data to identify value opportunities</p>
          </div>
          <div className={styles.feature}>
            <FaChartBar size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Weighted Scoring</h3>
            <p>Proprietary scoring system evaluates multiple factors for optimal selection accuracy</p>
          </div>
          <div className={styles.feature}>
            <FaCalculator size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Value Focus</h3>
            <p>Emphasis on finding the best value bets for consistent long-term profitability</p>
          </div>
          <div className={styles.feature}>
            <FaCheckCircle size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Expert Verification</h3>
            <p>All algorithm selections are verified by our expert team before publication</p>
          </div>
          <div className={styles.feature}>
            <FaHistory size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Track Record</h3>
            <p>Proven history of consistent returns with full transparency on all selections</p>
          </div>
          <div className={styles.feature}>
            <FaBell size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Instant Updates</h3>
            <p>Get notifications as soon as new tips are posted to never miss an opportunity</p>
          </div>
        </div>
      </div>

      <div className={styles.trialPromo}>
        <div className={styles.promoContent}>
          <h2 className={styles.promoTitle}>Try VIP Access Free for 3 Days</h2>
          <ul className={styles.benefitsList}>
            <li>Daily Premium Selections</li>
            <li>Full Access to Historical Results & Analytics</li>
            <li>Email Notifications for New Tips</li>
            <li>Detailed Value Odds Analysis</li>
            <li>Performance Tracking Dashboard</li>
            <li>No Commitment Required</li>
          </ul>
          <div className={styles.pricingInfo}>
            <p className={styles.price}>£5<span>/month</span></p>
            <p className={styles.priceNote}>after 3-day free trial</p>
          </div>
          <button 
            onClick={handleSubscribe}
            className={styles.promoButton}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Start Your Free Trial'}
          </button>
          <p className={styles.cancelNote}>Cancel anytime during trial - No charges during trial period</p>
          {error && (
            <div className={styles.error}>
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;