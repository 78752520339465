import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Configure axios defaults
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const getAuthHeader = () => {
  try {
    const token = localStorage.getItem('access_token');
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return {};
  } catch (error) {
    console.error('Error getting auth header:', error);
    return {};
  }
};

export const hasValidToken = () => {
  const token = localStorage.getItem('access_token');
  const user = getCurrentUser();
  return !!(token && user);
};

export const getCurrentUser = () => {
  try {
    const userStr = localStorage.getItem('user');
    const token = localStorage.getItem('access_token');
    
    if (!userStr || !token) {
      return null;
    }

    const user = JSON.parse(userStr);
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return {
        ...user,
        is_staff: user.is_staff || false
      };
    }
    
    // Clean up invalid data
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
    return null;
  }
};

export const login = async (usernameOrEmail, password) => {
  const loginUrl = `${API_URL}/api/token/`;
  console.log('HERE IS API URL:', API_URL);
  
  try {
    console.log('Login request:', {
      url: loginUrl,
      data: { username_or_email: usernameOrEmail, password: '***' }
    });
    
    const response = await axios.post(loginUrl, {
      username_or_email: usernameOrEmail,  // Ensure this matches the backend expectation
      password: password
    });
    
    console.log('Login response:', response.data);
    
    if (response.data.access) {
      const userData = {
        username: response.data.username,
        email: response.data.email,
        is_staff: response.data.is_staff || false
      };
      
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
      return response.data;
    }
    throw new Error('No access token received');
  } catch (error) {
    console.error('Error details:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    const errorMessage = error.response?.data?.detail || 'Login failed';
    console.error('Login failed:', errorMessage);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  delete axios.defaults.headers.common['Authorization'];
};