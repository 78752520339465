export const calculateProfitLoss = (tips) => {
    let winOnlyProfit = 0;
    let eachWayProfit = 0;
    const WIN_STAKE = 10;
    const EW_STAKE = 5; // £5 E/W = £10 total stake

    tips.forEach(tip => {
        // Win Only Bets (£10)
        if (tip.result === 'WON') {
            winOnlyProfit += (WIN_STAKE * tip.odds) - WIN_STAKE;
        } else if (tip.result === 'LOST' || tip.result === 'PLACED') {
            winOnlyProfit -= WIN_STAKE; // Lose full stake on both losses and places
        } else if (tip.result === 'NR') {
            winOnlyProfit += 0; // Stake returned for non-runners
        }

        // Each Way Bets (£5 E/W = £10 total)
        if (tip.result === 'WON') {
            // Win part: £5 at full odds
            eachWayProfit += (EW_STAKE * tip.odds) - EW_STAKE;
            // Place part: £5 at 1/4 odds
            eachWayProfit += (EW_STAKE * ((tip.odds - 1) / 4 + 1)) - EW_STAKE;
        } else if (tip.result === 'PLACED') {
            // Lose win part
            eachWayProfit -= EW_STAKE;
            // Win place part at 1/4 odds
            eachWayProfit += (EW_STAKE * ((tip.odds - 1) / 4 + 1)) - EW_STAKE;
        } else if (tip.result === 'LOST') {
            eachWayProfit -= (EW_STAKE * 2); // Lose both parts
        } else if (tip.result === 'NR') {
            eachWayProfit += 0; // Stake returned
        }
    });

    return {
        winOnlyProfit,
        eachWayProfit
    };
};