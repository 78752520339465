import { useLocation, Link } from 'react-router-dom';
import { FaEnvelope, FaExclamationTriangle } from 'react-icons/fa';
import styles from '../styles/CheckEmailPage.module.css';

const CheckEmailPage = () => {
  const location = useLocation();
  const email = location.state?.email || 'your email';

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <FaEnvelope className={styles.icon} />
        </div>
        
        <h1 className={styles.title}>Check Your Email</h1>
        
        <p className={styles.message}>
          We've sent a verification link to <strong>{email}</strong>
        </p>
        
        <div className={styles.instructions}>
          <h2 className={styles.subtitle}>Next Steps:</h2>
          <ol className={styles.steps}>
            <li>Open your email inbox</li>
            <li>Click the verification link in our email</li>
            <li>Return to MisterTipster to log in</li>
          </ol>
        </div>

        <div className={styles.warning}>
          <FaExclamationTriangle className={styles.warningIcon} />
          <p>
            Can't find the email? Check your spam folder or click below to resend.
          </p>
        </div>

        <div className={styles.actions}>
          <Link to="/login" className={styles.loginButton}>
            Return to Login
          </Link>
          <button className={styles.resendButton}>
            Resend Verification Email
          </button>
        </div>

        <p className={styles.support}>
          Need help? <Link to="/contact" className={styles.supportLink}>Contact Support</Link>
        </p>
      </div>
    </div>
  );
};

export default CheckEmailPage;