import React, { useState, useEffect } from 'react';
import styles from '../styles/TipsPage.module.css';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link } from 'react-router-dom';

const TipsPage = () => {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedTips, setExpandedTips] = useState(new Set());
    const [activeFilter, setActiveFilter] = useState('all');
    const [isAdmin, setIsAdmin] = useState(false);
    
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            try {
                const user = JSON.parse(userStr);
                setIsAdmin(user.is_staff === true);
            } catch (error) {
                console.error('Error parsing user data:', error);
                setIsAdmin(false);
            }
        }
    }, []);

    const getFilteredTips = () => {
      if (activeFilter === 'all') return otherTips;
      return otherTips.filter(tip => tip.type === activeFilter);
    };

    const formatRaceTime = (dateTimeString) => {
      // First check if we're getting a full datetime string
      if (dateTimeString.includes('T')) {
        return dateTimeString.split('T')[1].substring(0, 5); // Get HH:MM from ISO string
      }
      
      // If we're getting just a time string
      if (dateTimeString.includes(':')) {
        return dateTimeString.substring(0, 5); // Get HH:MM from time string
      }
      
      // If we're getting date-time in a different format
      const parts = dateTimeString.split(' ');
      return parts.length > 1 ? parts[1].substring(0, 5) : dateTimeString;
    };

    const getAuthHeaders = () => {
      try {
          // Try both possible token storage methods
          const accessToken = localStorage.getItem('access_token');
          const userStr = localStorage.getItem('user');
          
          console.log('Debug - localStorage:', {
              accessToken: !!accessToken,
              userStr: !!userStr
          });
  
          if (accessToken) {
              return {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
              };
          }
  
          if (userStr) {
              const user = JSON.parse(userStr);
              if (user?.access) {
                  return {
                      'Authorization': `Bearer ${user.access}`,
                      'Content-Type': 'application/json',
                  };
              }
          }
  
          console.error('No valid token found in either storage location');
          return null;
      } catch (error) {
          console.error('Error getting auth headers:', error);
          return null;
      }
  };
  
  useEffect(() => {
    const fetchTips = async () => {
        try {
            const headers = getAuthHeaders();
            if (!headers) {
                throw new Error('No valid authentication token found');
            }

            console.log('Fetching tips from:', `${API_URL}/api/tips/`);
            const response = await axios.get(`${API_URL}/api/tips/`, { headers });
            
            // Filter for today's tips
            const today = new Date().toISOString().split('T')[0];
            const todaysTips = response.data.filter(tip => tip.date === today);
            
            console.log('Fetched tips:', todaysTips);
            setTips(todaysTips);
        } catch (err) {
            console.error('Error details:', {
                status: err.response?.status,
                statusText: err.response?.statusText,
                data: err.response?.data,
                message: err.message
            });

            if (err.response?.status === 401) {
                setError('Your session has expired. Please log in again.');
                // Optionally, redirect to login page or trigger a refresh token flow
                localStorage.removeItem('user'); // Clear invalid token
                window.location.href = '/login'; // Redirect to login
            } else {
                setError(err.response?.data?.message || err.message || 'Failed to fetch tips');
            }
        } finally {
            setLoading(false);
        }
    };

    fetchTips();
}, [API_URL]);

    const toggleTip = (tipId) => {
      setExpandedTips(prev => {
        const newSet = new Set(prev);
        if (newSet.has(tipId)) {
          newSet.delete(tipId);
        } else {
          newSet.add(tipId);
        }
        return newSet;
      });
    };

    const formatDate = (date) => {
      return new Date().toLocaleDateString('en-GB', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    };

    // Capitalize the first letter of each word in a string
    const capitalizeWords = (str) => {
      return str.split(' ').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    };

    if (loading) return <LoadingSpinner text="Loading tips..." />;
    if (error) return <div className={styles.error}>Error: {error}</div>;

    const napTip = tips.find(tip => tip.type === 'NAP');
    const otherTips = tips.filter(tip => tip.type !== 'NAP');

    return (
      <div className={styles.contentWrapper}>
        
        {/* Date Header */}
        <h1 className={styles.dateHeader}>
          {formatDate(new Date())}
        </h1>
    
        {tips.length === 0 ? (
          <div className={styles.noTips}>
            <h2>No tips available yet.</h2>
            <p>Tips are usually ready by 10:30-11am!</p>
          </div>
        ) : (
          <>
            {/* Stats Bar */}
            <div className={styles.statsBar}>
              <div className={styles.statItem}>
                <span className={styles.statLabel}>Total Tips</span>
                <span className={styles.statValue}>{tips.length}</span>
              </div>
              <div className={styles.statItem}>
                <span className={styles.statLabel}>Average Odds</span>
                <span className={styles.statValue}>
                  {(tips.reduce((acc, tip) => acc + Number(tip.odds), 0) / tips.length).toFixed(2)}
                </span>
              </div>
              <div className={styles.statItem}>
                <span className={styles.statLabel}>Meetings</span>
                <span className={styles.statValue}>
                  {new Set(tips.map(tip => tip.location)).size}
                </span>
              </div>
            </div>
    
            {/* NAP Section */}
            {napTip && (
            <section className={`${styles.napSection} ${
              napTip.result === 'WON' ? styles.napWon :
              napTip.result === 'LOST' ? styles.napLost :
              napTip.result === 'PLACED' ? styles.napPlaced :
              napTip.result === 'NR' ? styles.napNonRunner : ''
            }`}>
              {napTip.result && (
                <span className={styles.napResultText}>
                  {napTip.result}
                </span>
              )}
            <div className={styles.napSection}>
              <h2>NAP of the Day</h2>
              
              <div className={styles.raceDetails}>
                <div className={styles.detailItem}>
                  <span className={styles.detailLabel}>JOCKEY</span>
                  <span className={styles.detailValue}>{napTip.jockey}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.detailLabel}>TRAINER</span>
                  <span className={styles.detailValue}>{napTip.trainer}</span>
                </div>
              </div>
              </div>
              <div className={styles.napContent}>
                <div className={styles.napMainInfo}>
                  <div className={styles.napHorseInfo}>
                    {napTip.image_link && (
                      <img 
                        src={napTip.image_link} 
                        alt="Racing silks" 
                        className={styles.silksImage}
                      />
                    )}
                    <div>
                      <h3 className={styles.horseName}>{napTip.horse}</h3>
                      <div className={styles.napDetails}>
                        <span className={styles.location}>{capitalizeWords(napTip.location)}</span>
                        <span className={styles.raceTime}> {formatRaceTime(napTip.race_time)}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.napActionArea}>
                    <span className={styles.odds}>{Number(napTip.odds).toFixed(2)}</span>
                  </div>
                </div>
                <div className={styles.analysis}>
                  <div className={styles.analysisSection}>
                    <h4>Analysis</h4>
                    <p>{napTip.reasons}</p>
                  </div>
                  <div className={styles.analysisSection}>
                    <h4>Comment</h4>
                    <p>{napTip.racing_post_comment}</p>
                  </div>
                </div>
              </div>
            </section>
          )}
    
            {/* Other Tips Section */}
            <section className={styles.otherTipsSection}>
            <div className={styles.sectionHeader}>
            <h2>Today's Other Tips</h2>
            <div className={styles.filterButtons}>
              <button 
                className={`${styles.filterButton} ${activeFilter === 'all' ? styles.active : ''}`}
                onClick={() => setActiveFilter('all')}
              >
                All
              </button>
              <button 
                className={`${styles.filterButton} ${activeFilter === 'Solid Pick' ? styles.active : ''}`}
                onClick={() => setActiveFilter('Solid Pick')}
              >
                Solid Pick
              </button>
              <button 
                className={`${styles.filterButton} ${activeFilter === 'Value Pick' ? styles.active : ''}`}
                onClick={() => setActiveFilter('Value Pick')}
              >
                Value Pick
              </button>
              <button 
                className={`${styles.filterButton} ${activeFilter === 'Each Way Pick' ? styles.active : ''}`}
                onClick={() => setActiveFilter('Each Way Pick')}
              >
                Each Way Pick
              </button>
            </div>
          </div>

          <div className={styles.tipsList}>
          {getFilteredTips().map(tip => (
            <div key={tip.id} className={`${styles.tipCard} ${tip.result ? styles[tip.result.toLowerCase()] : ''}`}>
              <div className={styles.tipType}>
                {tip.type}
              </div>
              <div className={styles.tipMain}>
                <div className={styles.horseInfo}>
                  {tip.image_link && (
                    <img 
                      src={tip.image_link} 
                      alt="Racing silks" 
                      className={styles.silksImage}
                    />
                  )}
                  <div className={styles.horseDetails}>
                    <h3>{tip.horse}</h3>
                    <div className={styles.raceInfo}>
                      <span className={styles.location}>{capitalizeWords(tip.location)}</span>
                      <span className={styles.raceTime}>{formatRaceTime(tip.race_time)}</span>
                    </div>
                  </div>
                </div>
                
                {/* Add the new details row here */}
                <div className={styles.raceDetails}>
                  <div className={styles.detailItem}>
                    <span className={styles.detailLabel}>JOCKEY</span>
                    <span className={styles.detailValue}>{tip.jockey}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailLabel}>TRAINER</span>
                    <span className={styles.detailValue}>{tip.trainer}</span>
                  </div>
                </div>
              </div>
              {tip.result && (
                <div className={styles.resultText}>
                  {tip.result === 'NON_RUNNER' ? 'Non Runner' : tip.result}
                </div>
              )}
              <div className={styles.actionArea}>
                {/* <span className={styles.confidence}>{tip.confidence}</span> */}
                <span className={styles.odds}>{Number(tip.odds).toFixed(2)}</span>
                <button 
                  className={styles.viewAnalysisBtn}
                  onClick={() => toggleTip(tip.id)}
                >
                  {expandedTips.has(tip.id) ? 'Hide Analysis' : 'View Analysis'}
                </button>
              </div>
              {expandedTips.has(tip.id) && (
                <div className={styles.analysis}>
                  <div className={styles.analysisSection}>
                    <h4>Analysis</h4>
                    <p>{tip.reasons}</p>
                  </div>
                  <div className={styles.analysisSection}>
                    <h4>Comment</h4>
                    <p>{tip.racing_post_comment}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
          </section>
          </>
        )}
      </div>
    );
};

export default TipsPage;