import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Change this
import styles from '../../styles/SubscriptionSuccess.module.css';
import Navbar from '../../components/Navbar';
import { getCurrentUser } from '../../services/authService';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();  // Change this
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const user = getCurrentUser();
    if (!user) {
      navigate('/login');  // Change this
      return;
    }
    setIsLoading(false);
  }, [navigate]);

  if (isLoading) {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.successCard}>
            <h2>Loading...</h2>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.successCard}>
          <h1>🎉 Subscription Successful!</h1>
          <p>Thank you for subscribing to MisterTipster VIP!</p>
          <p>You now have access to all our premium features and VIP tips.</p>
          
          <div className={styles.buttonContainer}>
            <button 
              onClick={() => navigate('/vip')}  // Change this
              className={styles.vipButton}
            >
              Go to VIP Area
            </button>
            
            <button 
              onClick={() => navigate('/account')}  // Change this
              className={styles.accountButton}
            >
              Back to Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionSuccess;