import React from 'react';
import styles from '../styles/ResponsibleGamblingPage.module.css';

const ResponsibleGamblingPage = () => {
  return (
    <div className={styles.container}>
      <h1>Responsible Gambling</h1>
      
      <section className={styles.section}>
        <h2>Gambling Should Be Fun</h2>
        <p>
          Gambling should always be an enjoyable leisure activity, not a way to make money. 
          Only bet what you can afford to lose and always stay in control of your betting.
        </p>
      </section>

      <section className={styles.section}>
        <h2>Signs of Problem Gambling</h2>
        <ul>
          <li>Spending more money and time on gambling than you can afford</li>
          <li>Finding it hard to manage or stop your gambling</li>
          <li>Having arguments with family or friends about gambling</li>
          <li>Losing interest in usual activities or hobbies</li>
          <li>Always thinking or talking about gambling</li>
          <li>Lying about your gambling or hiding it from others</li>
          <li>Chasing losses or gambling to get out of financial trouble</li>
          <li>Gambling until all your money is gone</li>
          <li>Borrowing money, selling possessions or not paying bills to fund gambling</li>
          <li>Gambling to escape other problems in your life</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>Get Help</h2>
        <div className={styles.helpResources}>
          <div className={styles.helpCard}>
            <h3>GamCare</h3>
            <p>Free support, information and counselling for problem gambling.</p>
            <a href="https://www.gamcare.org.uk" target="_blank" rel="noopener noreferrer">Visit Website</a>
            <p className={styles.helpline}>Call: 0808 8020 133 (24/7)</p>
          </div>

          <div className={styles.helpCard}>
            <h3>BeGambleAware</h3>
            <p>Free confidential help and support with problem gambling.</p>
            <a href="https://www.begambleaware.org" target="_blank" rel="noopener noreferrer">Visit Website</a>
            <p className={styles.helpline}>Call: 0808 8020 133</p>
          </div>

          <div className={styles.helpCard}>
            <h3>Gamblers Anonymous</h3>
            <p>Support groups and fellowship for problem gamblers.</p>
            <a href="https://www.gamblersanonymous.org.uk" target="_blank" rel="noopener noreferrer">Visit Website</a>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <h2>Self-Exclusion Options</h2>
        <p>
          If you feel your gambling is becoming a problem, you can self-exclude from gambling websites and betting shops:
        </p>
        <ul>
          <li>
            <a href="https://www.gamstop.co.uk" target="_blank" rel="noopener noreferrer">GAMSTOP</a>
            {' '}- Exclude from all online gambling sites
          </li>
          <li>
            <a href="https://self-exclusion.co.uk" target="_blank" rel="noopener noreferrer">MOSES</a>
            {' '}- Exclude from betting shops
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>Responsible Gambling Tools</h2>
        <ul>
          <li>Set deposit limits</li>
          <li>Set time limits</li>
          <li>Take regular breaks</li>
          <li>Keep track of time and money spent</li>
          <li>Never chase losses</li>
          <li>Don't gamble when upset or stressed</li>
          <li>Balance gambling with other activities</li>
        </ul>
      </section>
    </div>
  );
};

export default ResponsibleGamblingPage;