import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';
import styles from '../styles/HomeResultsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';

const HomeResultsPage = () => {
  const navigate = useNavigate();
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tipsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    try {
      console.log('Fetching from:', `${API_URL}/api/tips/public-results/`);
      const response = await fetch(`${API_URL}/api/tips/public-results/`);
      console.log('Response status:', response.status);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error data:', errorData);
        throw new Error(`Failed to fetch results: ${errorData.detail || 'Unknown error'}`);
      }
      const data = await response.json();
      
      // Filter for last 30 days
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      const filteredData = data.filter(tip => {
        const tipDate = new Date(tip.date);
        return tipDate >= thirtyDaysAgo;
      });
      
      setTips(filteredData);
    } catch (err) {
      console.error('Error details:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const calculateStats = (tips) => {
    // Filter NAP tips (check both upper and lower case)
    const napTips = tips.filter(tip => 
      (tip.type === "NAP" || tip.type === "nap") && 
      (tip.result !== "PENDING" && tip.result !== "NR")
    );
  
    console.log('NAP TIPS:', napTips.map(tip => ({
      horse: tip.horse,
      odds: tip.odds,
      result: tip.result,
      date: tip.date
    })));
  
    const napWins = napTips.filter(tip => tip.result === "WON").length;
    const napStrikeRate = napTips.length > 0 ? ((napWins / napTips.length) * 100).toFixed(1) : '0';
  
    // Calculate NAP profits
    let napProfits = 0;
    napTips.forEach(tip => {
      const previousProfit = napProfits;
      if (tip.result === "WON") {
        napProfits += (Number(tip.odds) - 1) * 10;
        console.log(`NAP WIN: ${tip.horse} @ ${tip.odds} | Previous: ${previousProfit} | Added: ${((Number(tip.odds) - 1) * 10)} | New Total: ${napProfits}`);
      } else if (tip.result === "LOST" || tip.result === "PLACED") {
        napProfits -= 10;
        console.log(`NAP ${tip.result}: ${tip.horse} | Previous: ${previousProfit} | Subtracted: 10 | New Total: ${napProfits}`);
      }
    });
  
    // Filter Premium tips (NAP, Solid Pick, Value Pick only)
    const premiumTips = tips.filter(tip => 
      (tip.type === "NAP" || 
       tip.type === "Solid Pick" || 
       tip.type === "Value Pick" || 
       tip.type === "Each Way Pick") && // Add any other types here
      tip.result !== "PENDING"
    );
  
    console.log('PREMIUM TIPS:', premiumTips.map(tip => ({
      horse: tip.horse,
      type: tip.type,
      odds: tip.odds,
      result: tip.result,
      date: tip.date
    })));
  
    // Calculate Premium profits
    let premiumProfits = 0;
    premiumTips.forEach(tip => {
      if (tip.result === "WON") {
        premiumProfits += (Number(tip.odds) - 1) * 10;
      } else if (tip.result === "LOST" || tip.result === "PLACED") {
        premiumProfits -= 10;
      }
    });
 
    // Find best winning odds from ALL winners this month
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    
    const allWinners = tips.filter(tip => 
      tip.result === "WON" && 
      new Date(tip.date) >= firstDayOfMonth
    );
    
    const bestWin = allWinners.sort((a, b) => Number(b.odds) - Number(a.odds))[0];
    const bestWinDetails = bestWin ? {
      odds: bestWin.odds,
      horse: bestWin.horse,
      date: bestWin.date,
      location: capitalizeFirstLetter(bestWin.location)
    } : { odds: 0, horse: '', date: '', location: '' };
  
    return {
      napStrikeRate,
      napProfits: napProfits.toFixed(2),
      premiumProfits: premiumProfits.toFixed(2),
      bestWin: bestWinDetails
    };
  };

  const stats = calculateStats(tips);

  if (loading) return <LoadingSpinner text="Loading results..." />;
  if (error) return <div className={styles.error}>Error: {error}</div>;

  const indexOfLastTip = currentPage * tipsPerPage;
  const indexOfFirstTip = indexOfLastTip - tipsPerPage;
  const currentTips = tips.slice(indexOfFirstTip, indexOfLastTip);
  const totalPages = Math.ceil(tips.length / tipsPerPage);

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.mainTitle}>Our Track Record</h1>

      {/* New Statistics Section */}
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <h3>NAP Strike Rate</h3>
          <div className={styles.statValue}>
            {stats.napStrikeRate > 0 ? `${stats.napStrikeRate}%` : '0%'}
          </div>
          <p>All Time</p>
        </div>
        <div className={styles.statCard}>
          <h3>NAP Profits</h3>
          <div className={styles.statValue}>
            £{stats.napProfits}
          </div>
          <p>All Time</p>
        </div>
        <div className={styles.statCard}>
          <h3>Premium Tips Profits</h3>
          <div className={styles.statValue}>
            £{stats.premiumProfits}
          </div>
          <p>All Time</p>
        </div>
      </div>

      {/* Best Winner Highlight */}
      {stats.bestWin.odds > 0 && (
        <div className={styles.highlightCard}>
          <h3 className={styles.highlightTitle}>Biggest Winner This Month</h3>
          <div className={styles.winnerDetails}>
            <span className={styles.bigWinnerHorse}>{stats.bestWin.horse}</span>
            <span className={styles.bigWinnerLocation}>{stats.bestWin.location}</span>
            <div className={styles.winnerStats}>
              <span className={styles.bigWinnerOdds}>{stats.bestWin.odds}</span>
              <span className={styles.bigWinnerDate}>{stats.bestWin.date}</span>
            </div>
          </div>
        </div>
      )}

      {/* Recent Results */}
      <div className={styles.recentResults}>
        <h3 className={styles.sectionTitle}>Recent Winners</h3>
        <div className={styles.winnersList}>
          {tips
            .filter(tip => tip.result === 'WON')
            .sort((a, b) => Number(b.odds) - Number(a.odds)) // Sort by highest odds
            .slice(0, 6) // Take top 6 highest odds winners
            .map(tip => (
              <div key={tip.id} className={styles.winnerCard}>
                <div className={styles.horseInfo}>
                  <span className={styles.horseName}>{tip.horse}</span>
                  <span className={styles.courseLocation}>{capitalizeFirstLetter(tip.location)}</span>
                </div>
                <span className={styles.winnerOdds}>{tip.odds}</span>
              </div>
            ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className={styles.ctaSection}>
        <h2>Join Our Winning Team</h2>
        <p>Get access to all our premium tips with a 3 day trial!</p>
        <button className={styles.ctaButton} onClick={() => navigate('/account')}>
          Subscribe Now
        </button>
      </div>
    </div>
  );
};

const getRowClass = (result) => {
  switch (result) {
    case 'WON':
      return styles.winner;
    case 'LOST':
      return styles.loser;
    case 'NR':
      return styles.nr;
    case 'PLACED':
      return styles.placed;
    default:
      return '';
  }
};

export default HomeResultsPage;