import React, { useState, useEffect, useMemo } from 'react';
import styles from '../styles/AnalyticsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';

const AnalyticsPage = () => {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTimeframe, setSelectedTimeframe] = useState('all'); // all, year, 6months, 3months, month
    const [betAmount, setBetAmount] = useState(10);
    const API_URL = process.env.REACT_APP_API_URL;
    const [selectedPeriod, setSelectedPeriod] = useState('7days');

    const displayPeriods = [
        { label: "Last 7 Days", value: "7days" },
        { label: "Last 30 Days", value: "30days" },
        { label: "Best Month", value: "bestMonth" },
        { label: "All Time", value: "all" }
    ];

    const getAuthHeaders = () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            const userStr = localStorage.getItem('user');
            
            console.log('Debug - localStorage:', {
                accessToken: !!accessToken,
                userStr: !!userStr
            });

            if (accessToken) {
                return {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                };
            }

            if (userStr) {
                const user = JSON.parse(userStr);
                if (user?.access) {
                    return {
                        'Authorization': `Bearer ${user.access}`,
                        'Content-Type': 'application/json',
                    };
                }
            }

            console.error('No valid token found in either storage location');
            return null;
        } catch (error) {
            console.error('Error getting auth headers:', error);
            return null;
        }
    };

    useEffect(() => {
        fetchTips();
    }, []);

    const fetchTips = async () => {
        try {
            const headers = getAuthHeaders();
            if (!headers) {
                throw new Error('No valid authentication token found');
            }

            console.log('Fetching tips from:', `${API_URL}/api/tips/`);
            const response = await axios.get(`${API_URL}/api/tips/`, { headers });
            
            // Debug log the received data
            console.log('Received tips:', response.data);
            
            // Filter out pending tips
            const completedTips = response.data.filter(tip => tip.result !== 'PENDING');
            console.log('Completed tips:', completedTips);
            
            setTips(completedTips);
        } catch (err) {
            console.error('Error details:', {
                status: err.response?.status,
                statusText: err.response?.statusText,
                data: err.response?.data,
                message: err.message
            });

            if (err.response?.status === 401) {
                setError('Your session has expired. Please log in again.');
                localStorage.removeItem('user');
                window.location.href = '/login';
            } else {
                setError(err.response?.data?.message || err.message || 'Failed to fetch tips');
            }
        } finally {
            setLoading(false);
        }
    };

    const filterTipsByTimeframe = (tips, timeframe) => {
        const now = new Date();
        const cutoffDate = new Date();
        
        switch(timeframe) {
            case '7days':
                cutoffDate.setDate(now.getDate() - 7);
                break;
            case 'month':
                cutoffDate.setMonth(now.getMonth() - 1);
                break;
            case '3months':
                cutoffDate.setMonth(now.getMonth() - 3);
                break;
            case '6months':
                cutoffDate.setMonth(now.getMonth() - 6);
                break;
            case 'year':
                cutoffDate.setFullYear(now.getFullYear() - 1);
                break;
            default:
                return tips;
        }
        
        return tips.filter(tip => new Date(tip.date) >= cutoffDate);
    };

    const calculateNapStats = (tips) => {
        if (!tips?.length) return null;
        
        // Filter NAP tips (case-insensitive)
        const napTips = tips.filter(tip => tip.type?.toLowerCase() === 'nap');
        const completedTips = napTips.filter(tip => tip.result !== 'PENDING' && tip.result !== 'NR');
        
        // Calculate streaks and profit
        let currentStreak = 0;
        let bestStreak = 0;
        let totalProfit = 0;
        
        // Sort tips by date for streak calculation
        const sortedTips = [...completedTips].sort((a, b) => 
            new Date(b.date) - new Date(a.date)
        );

        // Calculate current streak (continue until first non-win)
        for (const tip of sortedTips) {
            if (tip.result === 'WON') {
                currentStreak++;
            } else {
                break;
            }
        }

        // Calculate best streak
        let tempStreak = 0;
        for (const tip of sortedTips) {
            if (tip.result === 'WON') {
                tempStreak++;
                if (tempStreak > bestStreak) {
                    bestStreak = tempStreak;
                }
            } else {
                tempStreak = 0;
            }
        }

        // Calculate total profit from ALL NAP tips
        completedTips.forEach(tip => {
            if (tip.result === 'WON') {
                totalProfit += (betAmount * Number(tip.odds)) - betAmount;
            } else if (tip.result === 'LOST' || tip.result === 'PLACED') {
                // Lose stake for both LOST and PLACED results
                totalProfit -= betAmount;
            }
            // NR cases still don't affect profit
        });

        console.log('NAP Stats Calculation:', {
            totalTips: completedTips.length,
            winners: completedTips.filter(t => t.result === 'WON').length,
            losers: completedTips.filter(t => t.result === 'LOST').length,
            placed: completedTips.filter(t => t.result === 'PLACED').length,
            totalProfit
        });

        return {
            currentStreak,
            bestStreak,
            totalProfit
        };
    };

    const findBiggestWinner = (completedTips) => {
        return completedTips
            .filter(tip => tip.result === 'WON')
            .reduce((best, tip) => {
                const odds = Number(tip.odds);
                const return_ = 10 * odds; // £10 stake
                const profit = return_ - 10;
                
                if (return_ > best.return_) {
                    return {
                        odds,
                        return_,
                        profit,
                        date: tip.date,
                        type: tip.type,
                        horse: tip.horse
                    };
                }
                return best;
            }, { odds: 0, return_: 0, profit: 0, date: null, type: null, horse: null });
    };

    const calculateOverallStats = (tips) => {
        if (!tips?.length) return null;
        
        const completedTips = tips.filter(tip => tip.result !== 'PENDING' && tip.result !== 'NR');
        
        // Calculate daily profits for both bet types
        const dailyProfits = {};
        const weeklyProfits = {};
        const monthlyProfits = {};

        completedTips.forEach(tip => {
            const date = new Date(tip.date);
            const dayKey = date.toISOString().split('T')[0];
            const weekKey = `${date.getFullYear()}-W${getWeekNumber(date)}`;
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

            // Initialize if not exists
            dailyProfits[dayKey] = dailyProfits[dayKey] || { win: { profit: 0, tips: [] }, ew: { profit: 0, tips: [] } };
            weeklyProfits[weekKey] = weeklyProfits[weekKey] || { win: { profit: 0, tips: [] }, ew: { profit: 0, tips: [] } };
            monthlyProfits[monthKey] = monthlyProfits[monthKey] || { win: { profit: 0, tips: [] }, ew: { profit: 0, tips: [] } };

            // Calculate win-only profit/loss (£10 stake)
            const winProfitLoss = tip.result === 'WON' 
                ? (10 * Number(tip.odds)) - 10
                : -10;

            // Calculate E/W profit/loss (£5 E/W = £10 total stake)
            let ewProfitLoss = 0;
            const odds = Number(tip.odds);
            const placeOdds = (odds - 1) / 4 + 1;

            if (tip.result === 'WON') {
                // Win part (£5) + Place part (£5)
                ewProfitLoss = (5 * odds) + (5 * placeOdds) - 10;
            } else if (tip.result === 'PLACED') {
                // Only place part pays (£5 stake)
                ewProfitLoss = (5 * placeOdds) - 10;
            } else {
                ewProfitLoss = -10;
            }

            // Add to daily totals
            dailyProfits[dayKey].win.profit += winProfitLoss;
            dailyProfits[dayKey].win.tips.push(tip);
            dailyProfits[dayKey].ew.profit += ewProfitLoss;
            dailyProfits[dayKey].ew.tips.push(tip);

            // Add to weekly totals
            weeklyProfits[weekKey].win.profit += winProfitLoss;
            weeklyProfits[weekKey].win.tips.push(tip);
            weeklyProfits[weekKey].ew.profit += ewProfitLoss;
            weeklyProfits[weekKey].ew.tips.push(tip);

            // Add to monthly totals
            monthlyProfits[monthKey].win.profit += winProfitLoss;
            monthlyProfits[monthKey].win.tips.push(tip);
            monthlyProfits[monthKey].ew.profit += ewProfitLoss;
            monthlyProfits[monthKey].ew.tips.push(tip);
        });

        // Find best periods
        const findBest = (profits) => {
            return Object.entries(profits).reduce((best, [date, data]) => {
                const winBetter = data.win.profit > best.win.profit;
                const ewBetter = data.ew.profit > best.ew.profit;
                return {
                    win: winBetter ? { date, profit: data.win.profit, tipCount: data.win.tips.length } : best.win,
                    ew: ewBetter ? { date, profit: data.ew.profit, tipCount: data.ew.tips.length } : best.ew
                };
            }, { 
                win: { profit: -Infinity, tipCount: 0 }, 
                ew: { profit: -Infinity, tipCount: 0 } 
            });
        };

        return {
            bestDay: findBest(dailyProfits),
            bestWeek: findBest(weeklyProfits),
            bestMonth: findBest(monthlyProfits),
            biggestWin: findBiggestWinner(completedTips)
        };
    };

    // Helper function to get week number
    const getWeekNumber = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    };

    const calculateEWStats = (tips, betAmount) => {
        const ewTips = tips.filter(tip => tip.type === 'Each Way Pick');
        const completedTips = ewTips.filter(tip => tip.result !== 'PENDING');
        
        if (!completedTips.length) return null;

        const unitStake = betAmount / 2; // Split stake between win and place
        let totalProfit = 0;
        let wins = 0;
        let places = 0;
        let losses = 0;
        let bestPayout = 0;
        
        completedTips.forEach(tip => {
            const odds = Number(tip.odds);
            const placeOdds = (odds - 1) / 4 + 1; // Standard place terms (1/4 odds)
            let thisReturn = 0; // Track return for this individual bet
            
            if (tip.result === 'WON') {
                // Win part: full odds
                const winProfit = (unitStake * odds) - unitStake;
                // Place part: place odds
                const placeProfit = (unitStake * placeOdds) - unitStake;
                thisReturn = winProfit + placeProfit + betAmount; // Add back original stake for total return
                
                totalProfit += (winProfit + placeProfit);
                wins++;
            }
            else if (tip.result === 'PLACED') {
                // Only place part pays out
                const placeProfit = (unitStake * placeOdds) - unitStake;
                thisReturn = placeProfit + (betAmount/2); // Add back place stake for total return
                
                totalProfit += placeProfit - unitStake; // Subtract win stake as it's lost
                places++;
            }
            else if (tip.result === 'LOST') {
                thisReturn = 0;
                totalProfit -= betAmount;
                losses++;
            }
            
            bestPayout = Math.max(bestPayout, thisReturn);
        });

        const totalBets = wins + places + losses;
        const successRate = ((wins + places) / totalBets * 100).toFixed(1);

        return {
            totalBets,
            wins,
            places,
            losses,
            successRate,
            profit: totalProfit.toFixed(2),
            bestPayout: bestPayout.toFixed(2),
            averageStake: betAmount.toFixed(2)
        };
    };

    const calculateProfitLoss = (tips) => {
        let winOnlyProfit = 0;
        let eachWayProfit = 0;

        tips.forEach(tip => {
            if (tip.result === 'WON') {
                winOnlyProfit += (betAmount * Number(tip.odds)) - betAmount;
            } else if (tip.result === 'PLACED') {
                winOnlyProfit -= betAmount;
                // For each way bets, calculate place returns
                if (tip.type === 'Each Way Pick') {
                    const placeOdds = (Number(tip.odds) - 1) / 4 + 1;
                    eachWayProfit += (betAmount/2 * placeOdds) - betAmount/2;
                }
            } else if (tip.result === 'LOST') {
                winOnlyProfit -= betAmount;
                if (tip.type === 'Each Way Pick') {
                    eachWayProfit -= betAmount;
                }
            }
        });

        return { winOnlyProfit, eachWayProfit };
    };

    const stats = useMemo(() => ({
        nap: calculateNapStats(tips),
        overall: calculateOverallStats(tips)
    }), [tips, betAmount]);

    const formatDate = (dateStr) => {
        if (!dateStr) return '-';
        return new Date(dateStr).toLocaleDateString('en-GB');
    };

    const formatWeek = (weekStr) => {
        if (!weekStr) return '-';
        
        const [year, week] = weekStr.split('-W');
        
        // Get the first day of the week
        const firstDay = new Date(year, 0, 1 + (week - 1) * 7);
        
        // Adjust to Monday (as first day of week)
        const monday = new Date(firstDay);
        monday.setDate(firstDay.getDate() + (1 - firstDay.getDay()));
        
        // Get Sunday by adding 6 days to Monday
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        
        // Format dates as DD-MM-YYYY
        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };
        
        return `${formatDate(monday)} - ${formatDate(sunday)}`;
    };

    const formatMonth = (monthStr) => {
        if (!monthStr) return '-';
        const [year, month] = monthStr.split('-');
        return new Date(year, month - 1).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' });
    };

    return (
        <div className={styles.analyticsContainer}>
            <div className={styles.header}>
                <h2>Performance Highlights</h2>
            </div>

            {loading ? <LoadingSpinner /> : (
                <div className={styles.statsContainer}>
                    {/* NAP Performance Card */}
                    <div className={styles.mainCard}>
                        <h3>NAP Performance</h3>
                        <div className={styles.streakContainer}>
                            <div className={styles.streakBox}>
                                <div className={styles.streakValue}>{stats?.nap?.currentStreak || 0}</div>
                                <div className={styles.streakLabel}>Current Streak</div>
                            </div>
                            <div className={styles.streakBox}>
                                <div className={styles.streakValue}>{stats?.nap?.bestStreak || 0}</div>
                                <div className={styles.streakLabel}>Best Streak</div>
                            </div>
                            <div className={styles.streakBox}>
                                <div className={`${styles.streakValue} ${stats?.nap?.totalProfit > 0 ? styles.profit : styles.loss}`}>
                                    {stats?.nap?.totalProfit > 0 ? '+' : ''}£{stats?.nap?.totalProfit?.toFixed(2) || '0.00'}
                                </div>
                                <div className={styles.streakLabel}>Total Profit/Loss</div>
                            </div>
                        </div>
                    </div>

                    {/* Overall Performance Cards */}
                    <div className={styles.profitGrid}>
                        <div className={styles.profitCard}>
                            <div className={styles.profitHeader}>Best Day</div>
                            <div className={styles.betTypes}>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£10 Win</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestDay?.win?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestDay?.win?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestDay?.win?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£5 E/W</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestDay?.ew?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestDay?.ew?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestDay?.ew?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.profitDate}>{formatDate(stats?.overall?.bestDay?.win?.date) || '-'}</div>
                            <div className={styles.tipCount}>{stats?.overall?.bestDay?.win?.tipCount || 0} tips</div>
                        </div>

                        <div className={styles.profitCard}>
                            <div className={styles.profitHeader}>Best Week</div>
                            <div className={styles.betTypes}>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£10 Win</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestWeek?.win?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestWeek?.win?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestWeek?.win?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£5 E/W</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestWeek?.ew?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestWeek?.ew?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestWeek?.ew?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.profitDate}>{formatWeek(stats?.overall?.bestWeek?.win?.date) || '-'}</div>
                            <div className={styles.tipCount}>{stats?.overall?.bestWeek?.win?.tipCount || 0} tips</div>
                        </div>

                        <div className={styles.profitCard}>
                            <div className={styles.profitHeader}>Best Month</div>
                            <div className={styles.betTypes}>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£10 Win</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestMonth?.win?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestMonth?.win?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestMonth?.win?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                                <div className={styles.betType}>
                                    <div className={styles.betTypeLabel}>£5 E/W</div>
                                    <div className={`${styles.profitAmount} ${stats?.overall?.bestMonth?.ew?.profit < 0 ? styles.loss : styles.profit}`}>
                                        {stats?.overall?.bestMonth?.ew?.profit > 0 ? '+' : ''}
                                        £{stats?.overall?.bestMonth?.ew?.profit?.toFixed(2) || '0.00'}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.profitDate}>{formatMonth(stats?.overall?.bestMonth?.win?.date) || '-'}</div>
                            <div className={styles.tipCount}>{stats?.overall?.bestMonth?.win?.tipCount || 0} tips</div>
                        </div>
                    </div>

                    {/* Biggest Winner Card */}
                    <div className={styles.winnerCard}>
                        <h3>Biggest Winner</h3>
                        <div className={styles.winnerContent}>
                            <div className={styles.winnerOdds}>
                                {stats?.overall?.biggestWin?.odds?.toFixed(2) || '0'}x
                            </div>
                            <div className={styles.winnerReturn}>
                                +£{(stats?.overall?.biggestWin?.return_ || 0).toFixed(2)}
                            </div>
                            {stats?.overall?.biggestWin?.horse && (
                                <div className={styles.winnerHorse}>{stats.overall.biggestWin.horse}</div>
                            )}
                            <div className={styles.winnerDate}>
                                {formatDate(stats?.overall?.biggestWin?.date) || '-'}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnalyticsPage;